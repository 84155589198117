<template lang="pug">
.om-non-wizard
  top(:percent="percent")
  .om-non-wizard-body
    .container
      component(:is="step" @select="onSelect")
  new-campaign
  last-requested-date-old
</template>

<script>
  import Top from '@/components/Wizard/Top';
  import NewCampaign from '@/components/Modals/NewCampaign.vue';
  import LastRequestedDateOld from '@/components/Modals/LastRequestDateOld.vue';
  import { Goals, Types, order } from './pages';

  export default {
    components: { Top, NewCampaign, LastRequestedDateOld, Goals, Types },
    data() {
      return {
        selection: null,
      };
    },
    computed: {
      stepIndex() {
        const index = order.indexOf(this.step);
        return index || 0;
      },
      step() {
        const { step } = this.$route.params;
        return step || order[0];
      },
      percent() {
        return Math.round(((this.stepIndex + 1) / (order.length + 1)) * 100);
      },
      isAgency() {
        const { account } = this.$route.query;
        return account === 'agency';
      },
      isOnboarding() {
        return this.$route.fullPath.includes('/onboarding');
      },
      newCampaignRouteName() {
        const name = 'new_campaign';
        if (this.isOnboarding) return `${name}_onboarding`;
        return name;
      },
    },
    created() {
      this.saveOnboardingStage(this.$route.fullPath);
    },
    methods: {
      onSelect(value) {
        if (value === 'dynamicContent') {
          this.$modal.show('name-campaign', { canChange: false, isDynamicContent: true });
          return;
        }

        this.selection = value;
        this.nextStep();
      },
      async saveOnboardingStage(fullPath) {
        if (this.isOnboarding) {
          await this.$store.dispatch('saveOnboardingStage', fullPath);
        } else {
          this.$store.dispatch('saveOnboardingStage', null);
        }
      },
      async nextStep() {
        const nextStep = order[this.stepIndex + 1];
        if (!nextStep || nextStep === 'types') {
          await this.saveOnboardingStage(null);
          return this.redirectToTemplateChooser();
        }
        const step = order[this.stepIndex];
        this.$router.push({
          name: this.$route.name,
          params: { step: nextStep },
          query: { ...this.$route.query, [step]: this.selection },
        });
      },
      redirectToTemplateChooser() {
        return this.$router.push({ name: 'templates' });
      },
    },
  };
</script>

<style lang="sass">
  .container.om-non-wizard-container
    max-width: 855px
    margin-top: 60px
    margin-bottom: 60px
    .brand-select-card-title
      font-size: 1.125rem
</style>
