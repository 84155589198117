<template lang="pug">
div
  .container.om-non-wizard-container
    om-heading.text-center.mb-5.pb-5(h2) {{ $t('nonWizardFilter.title.goal') }}
    om-select-cards(:options="options" vertical @input="onSelect")
</template>

<script>
  export default {
    data() {
      return {
        currentStep: this.$route.params.step,
        options: [
          {
            key: this.$t('nonWizardFilter.goals.build_your_list.title'),
            details: this.$t('nonWizardFilter.goals.build_your_list.desc'),
            icon: 'grow-your-list',
            value: 'capture_email',
            arrow: false,
          },
          {
            key: this.$t('nonWizardFilter.goals.stop_cart_abandonment.title'),
            details: this.$t('nonWizardFilter.goals.stop_cart_abandonment.desc'),
            value: 'reduce_cart_abandonment',
            icon: 'stop-cart-abandonment',
            arrow: false,
          },
          {
            key: this.$t('nonWizardFilter.goals.guide_your_visitors.title'),
            details: this.$t('nonWizardFilter.goals.guide_your_visitors.desc'),
            value: 'promote_special_offers',
            icon: 'guide-your-visitors',
            arrow: false,
          },
          {
            key: this.$t('nonWizardFilter.goals.collect_feedback.title'),
            details: this.$t('nonWizardFilter.goals.collect_feedback.desc'),
            value: 'collect_feedback',
            icon: 'feedback',
            arrow: false,
          },
          {
            key: this.$t('nonWizardFilter.goals.promote_special_offers.title'),
            details: this.$t('nonWizardFilter.goals.promote_special_offers.desc'),
            value: 'promote_spec_offers',
            icon: 'promote-offer',
            arrow: false,
          },
          {
            key: this.$t('nonWizardFilter.goals.recommend_products.title'),
            details: this.$t('nonWizardFilter.goals.recommend_products.desc'),
            value: 'recommend_products',
            icon: 'product',
            arrow: false,
          },
        ],
      };
    },
    methods: {
      onSelect(option) {
        this.$emit('select', option);
      },
    },
  };
</script>
<style lang="sass">
  .brand-select-card
    @media screen and(max-width: 575px)
      max-height: 100px
  .brand-select-wrapper
    margin-bottom: 0.75rem
    &.col-12
      margin-bottom: 20px
</style>
