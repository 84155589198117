<template lang="pug">
div
  .container.om-non-wizard-displays-container
    om-heading.text-center.mb-5.pb-5(h2 v-html="$t('nonWizardFilter.title.display')")
    .row
      .col-12
        om-select-widgets(:options="options" @input="onSelect")
    om-button(v-if="displayBack" secondary @click="goBack") {{ $t('back') }}
</template>

<script>
  import { order } from './index';

  export default {
    props: {
      displayBack: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        options: [
          {
            key: this.$t('nonWizardFilter.displays.popup'),
            icon: 'popup',
            value: 'popup',
          },
          {
            key: this.$t('nonWizardFilter.displays.sidemessage'),
            icon: 'sidemessage',
            value: 'sidebar',
          },
          {
            key: this.$t('nonWizardFilter.displays.sticky_bar'),
            icon: 'sticky_bar',
            value: 'nanobar',
          },
          {
            key: this.$t('nonWizardFilter.displays.gamification'),
            icon: 'gamification',
            value: 'gamification',
          },
          {
            key: this.$t('nonWizardFilter.displays.full_screen'),
            icon: 'full_screen',
            value: 'interstitial',
          },
          {
            key: this.$t('nonWizardFilter.displays.survey'),
            icon: 'survey',
            value: 'survey',
          },
          {
            key: this.$t('nonWizardFilter.displays.embedded'),
            icon: 'embedded',
            value: 'embedded',
          },
          {
            key: this.$t('nonWizardFilter.displays.dynamicContent'),
            icon: 'dynamicContent',
            value: 'dynamicContent',
          },
        ],
      };
    },
    methods: {
      onSelect(option) {
        this.$emit('select', option);
      },
      goBack() {
        const currentIndex = order.indexOf(this.$route.params.step);
        const step = currentIndex > 0 ? order[currentIndex - 1] : order[0];
        this.$router.replace({ name: 'onboarding_non_wizard', params: { step } });
      },
    },
  };
</script>

<style lang="sass" scoped>
  .container.om-non-wizard-displays-container
    max-width: 720px
    margin-top: 60px
    margin-bottom: 60px
    .heading-2
      @media screen and (min-width: 768px)
        padding-left: 40px
        padding-right: 40px
</style>
