<template lang="pug">
om-modal(name="last-request-date-old" :width="520" color="light" @beforeOpen="beforeOpen")
  template(slot="modal-header")
    .brand-modal-action-icon
      close-icon.cursor-pointer(@click.native="hideModal" :width="14" :height="14" color="#C2C2C2")
  template(slot="modal-body")
    .row.justify-content-center.mt-3
      .col-12.mx-0
        .font-size-1--25.font-weight-semibold.text-center.mb-4 {{ $t('previewError.title') }}
        .font-size-1--15.text-center.mb-4 {{ $t('error.oldLastRequestDate') }}
  template(slot="modal-footer")
    .d-flex.justify-content-center.align-items-center(style="flex-flow: row")
      om-button.mr-3(ghost @click="openWebSite()") {{ $t('previewError.openSite') }}
      om-button(secondary @click="hideModal()") {{ $t('close') }}
</template>
<script>
  export default {
    data() {
      return {
        selectedDomain: null,
        domain: null,
      };
    },

    methods: {
      hideModal() {
        this.$modal.hide('last-request-date-old');
      },
      openWebSite() {
        this.$modal.hide('last-request-date-old');
        this.$bus.$emit('save-campaign', {
          selectedDomain: this.selectedDomain,
          domain: this.domain,
        });
      },
      beforeOpen(event) {
        this.selectedDomain = event.params.selectedDomain;
        this.domain = event.params.domain;
      },
    },
  };
</script>
<style lang="sass">
  .last-request-date-old
    .brand-modal-footer
      padding-top: 0 !important
    .brand-modal-body
      padding-left: 40px !important
      padding-right: 40px !important
      padding-bottom: 0 !important
</style>
